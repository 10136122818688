<template>
  <div v-if="systemMessages.length || systemErrors.length">
    <portal to="toast">
      <transition
        v-for="(message, messageIndex) in systemMessages"
        :key="`key-m-${messageIndex}`"
        name="modal"
      >
        <BootstrapToast
          @hide="removeError(message)"
          :message="message.message"
          :isError="false"
        ></BootstrapToast>
      </transition>
      <transition
        v-for="(error, errorIndex) in systemErrors"
        :key="`key-e-${errorIndex}`"
        name="modal"
      >
        <BootstrapToast
          @hide="removeError(error)"
          :message="error.error | errorReadable"
          :isError="true"
        ></BootstrapToast>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SystemMessage',
  components: {
    BootstrapToast: () => import('@/components/bootstrap/BootstrapToast'),
  },
  computed: {
    ...mapState(['systemMessages', 'systemErrors']),
  },
  methods: {
    removeMessage(message) {
      this.$store.dispatch('removeSystemMessage', message, { root: true });
    },
    removeError(error) {
      this.$store.dispatch('removeSystemError', error, { root: true });
    },
  },
};
</script>
